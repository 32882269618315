import useUserStore from '@/stores/user';
import useConfigStore from '@/stores/site/config';
import { storeToRefs } from 'pinia';

export default async function handleAgentEnter(to) {
	const userStore = useUserStore();
	const configStore = useConfigStore();
	const { user } = storeToRefs(userStore);

	const demoDomainFound = configStore.demoDomains.find(d => d === to.params.domainName);
	const demoUserFound = configStore.demoUsers.find(
		u => u.associate_id === user.value.associate_id
	);

	if (demoUserFound && demoDomainFound) {
		return true;
	}

	let isAllowed;
	switch (user.value?.user_type) {
		case 'mirus':
			// 1. `"mirus"`: can access all pages
			isAllowed = true;
			break;
		case 'agent':
			// 2. `"agent"`:  can only access `/agent/<associate_id>/` pages for `associate_id`
			isAllowed = to.path.indexOf(`agents/${userStore.user.associate_id}`) > -1;

			break;
		case 'staff':
			/*
			 *	3. `"staff"`: can access any `/agent/<associate_id>/` page when associate_id is in its
			 *	`staff_agents` list, but no other pages (aside from the atm landing page).
			 */
			isAllowed =
				to.path.indexOf(`atms/${userStore.user.associate_id}`) > -1 ||
				(to.path.indexOf('agents/') > -1 &&
					userStore.user.staff_agents.includes(to.params.agentID));
			break;
		case 'corp':
			/*
			 * 	4. `"corp"`:  can only access `/overview/` and `/overview/<sub page>/`
			 * 	(when sub page is listed in `corp_permissions`), along with the corp landing page.
			 * 	(<Overview component will handle routing permissions for subpages)
			 */
			isAllowed =
				to.path.indexOf(`employees/${userStore.user.associate_id}`) > -1 ||
				to.path.indexOf(`/overview`) === 0;
			break;
		case 'unknown':
			isAllowed =
				to.path.indexOf(`userStore.user.user/${userStore.user.user_details.email}`) > -1;
			break;
		default:
			isAllowed = false;
	}
	if (!isAllowed) {
		localStorage.setItem('accessDeniedPath', to.path);
		return '/forbidden';
	}
}
