import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';

import { watchEffect, ref, computed } from 'vue';

import * as Sentry from '@sentry/vue';
import useAlertStore from '@/stores/alerts';

export default defineStore('user', () => {
	const progress = ref(0);
	const user = ref(null);
	const sentryStatus = ref('init');

	const isMirusUser = computed(() => {
		return user.value?.internal ?? false;
	});

	async function getUserData() {
		progress.value = 0;

		try {
			const fetcher = useApi('api/v3/users/auth_info/', { alertOnFailure: false });
			watchEffect(() => (progress.value = fetcher.progress));
			const { data, statusCode } = await fetcher.json();

			if (statusCode.value === 403) {
				user.value = { authenticated: false };
			} else if (statusCode.value >= 400) {
				const alertStore = useAlertStore();
				alertStore.addAlert({
					message: 'there was an issue retrieving your data! Please try again later.',
				});
			}

			user.value = data?.value;
			if (import.meta.env.PROD && data.value) {
				sentryStatus.value = 'starting';
				await Sentry.setUser({
					id: data.value?.user_details?.identifier,
					email: data.value?.user_details?.email,
					segment: data.value?.user_type,
					username: data.value?.user,
					ip_address: '{{auto}}',
					userDetails: data.value,
				});
				sentryStatus.value = 'started';
			}
			return Promise.resolve();
		} catch (error) {
			user.value = { authenticated: false };
			sentryStatus.value = 'error';
			return Promise.reject(error);
		}
	}

	function onReset() {
		user.value = null;

		if (import.meta.env.PROD) {
			Sentry.setUser(null);
			sentryStatus.value = 'reset';
		}
		const alertStore = useAlertStore();
		alertStore.$reset();
	}
	return {
		progress,
		user,
		isMirusUser,
		sentryStatus,

		getUserData,
		onReset,
	};
});
